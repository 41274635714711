export const PACKAGE_FROM_SUBMIT_EVENT = 'package_form_submit'
export const PACKAGE_FLIGHT_IDS = {
  ROUND_TRIP: 'flight.route_switch',
  DESTINATION: 'flight.destination',
  AIRPORT: 'flight.airport',
  DATES: 'flight.dates',
  BOX1: 'flight.box1',
  TRAVELERS: 'flight.travelers',
  SEARCH_BTN: 'flight.search_btn',
  CABIN_CLASS: 'flight.cabin_class',
  CITIES:'flight.cities',
  CITIES_BOX1:'flight.cities_box1',
  ADD_FLIGHT_BTN:'flight.add_flight_btn',
}


export const PACKAGE_TRAIN_IDS = {
  ROUND_TRIP: 'train.route_switch',
  DESTINATION: 'train.destination',
  AIRPORT: 'train.airport',
  DATES: 'train.dates',
  BOX1: 'train.box1',
  TRAVELERS: 'train.travelers',
  SEARCH_BTN: 'train.search_btn',
  CABIN_CLASS: 'train.cabin_class'
}

export const PACKAGE_HOTEL_IDS = {
  DESTINATION: 'hotel.destination',
  DATES: 'hotel.dates',
  TRAVELERS: 'hotel.travelers',
  SEARCH_BTN: 'hotel.search_btn'
}

export const PACKAGE_BUNDLE_IDS = {
  DP_AIRPORT: 'bundle.dp_airport',
  AR_AIRPORT: 'bundle.ar_airport',
  FLIGHT_DATES: 'bundle.fl_dates',
  TRAVELERS: 'bundle.travelers',
  CABIN_CLASS: 'bundle.cabin_class',
  HOTEL_DATES: 'bundle.ht_dates',
  SEARCH_BTN: 'bundle.search_btn',
  STARS: 'bundle.stars',
  BOX1: 'bundle.box1'
}

export const PACKAGE_TRAIN_HOTEL_IDS = {
  ORIGIN: 'train_hotel.origin',
  DESTINATION: 'train_hotel.destination',
  FLIGHT_DATES: 'train_hotel.flight_dates',
  TRAVELERS: 'train_hotel.travelers',
  STARS: 'train_hotel.stars',
  HOTEL_DATES: 'train_hotel.hotel_dates',
  SEARCH_BTN: 'train_hotel.search_btn',
}


export const PACKAGE_TOUR_IDS = {
  DESTINATION: 'tour.destination',
  DATES: 'tour.dates',
  TRAVELERS: 'tour.travelers',
  SEARCH_BTN: 'tour.search_btn'
}

export const PACKAGE_TOUR_PACKAGE_IDS = {
  DESTINATION: 'tour_package.destination',
  DATE: 'tour_package.date',
  TRAVELERS: 'tour_package.travelers',
  SEARCH_BTN: 'tour_package.search_btn'
}

export const PACKAGE_TRANSFER_IDS = {
  AIRPORT: 'transfer.airport',
  DESTINATION: 'transfer.destination',
  TRAVELERS: 'transfer.travelers',
  PICKUP_DATE: 'transfer.pickup_date',
  PICKUP_TIME: 'transfer.pickup_time',
  SEARCH_BTN: 'transfer.search_btn'
}

export const PACKAGE_CAR_RENTAL_IDS = {
  PICKUP_LOCATION: 'car_rental.pickup_location',
  PICKUP_DATE: 'car_rental.pickup_date',
  PICKUP_TIME: 'car_rental.pickup_time',
  DRIVER_AGE: 'car_rental.driver_age',
  DROPOFF_LOCATION: 'car_rental.dropoff_location',
  DROPOFF_DATE: 'car_rental.dropoff_date',
  DROPOFF_TIME: 'car_rental.dropoff_time',
  SEARCH_BTN: 'transfer.search_btn'
}

export const TRIP_IDS = {
  DESTINATION: 'trip.destination',
  DATE: 'trip.date',
  TRAVELERS: 'trip.travelers',
  SEARCH_BTN: 'transfer.search_btn'
}


export const get_field_settings = ({
  field_id,
  settings,
  default_values = {}
}) => {
  const field_setting = settings?.[field_id] ?? {}
  return {
    hidden: field_setting.hidden ?? default_values?.hidden ?? false,
    disabled: field_setting.disabled ?? default_values?.disabled ?? false,
    order: field_setting.order ?? default_values?.order,
    dimension: {
      columns:
        field_setting.dimension?.columns ?? default_values?.dimension?.columns
    }
  }
}
