<template>
  <div class="q-row q-col-gutter-sm text-capitalize" style="position: relative">
    <div
      :class="class_name"
      :style="payload.from_airport ? '' : 'order: 1'"
    >
      <place-select
        dense
        outlined
        hide-bottom-space
        fill-input
        hide-selected
        prepend_icon="flight"
        v-model="payload.airport"
        :types="place_types"
        :rules="rules.airport"
        :label="$t('common.airport')"
        :hidden_icon="node.meta.hidden_icon"
      />
    </div>
    <div :class="class_name">
      <place-select
        v-model="payload.destination"
        :rules="rules.destination"
        :longitude="String(payload.airport?.location?.point?.coordinates?.[0])"
        :latitude="String(payload.airport?.location?.point?.coordinates?.[1])"
        dense
        outlined
        hide-bottom-space
        property_included
        fill-input
        hide-selected
        is_properties
        prepend_icon="hotel"
        :ancestors="payload?.airport?.ancestors"
        :hidden_icon="node.meta.hidden_icon"
        :label="$t('common.destination')"
      />
    </div>
    <q-btn
      :style="swap_btn_styles"
      round
      class="bg-white egs-swap-transfer-route-btn"
      icon="swap_horiz"
      outline
      @click="payload.from_airport = !payload.from_airport"
      dense
    />
  </div>
</template>
<script setup>
import PlaceSelect from 'src/components/common/place-select/index.vue'

import { computed, defineProps, onMounted, reactive } from 'vue'
import { stores } from 'src/stores'
import { DESKTOP_VIEWPORT } from 'src/constants'

const context_store = stores.use_context()

const props = defineProps({
  color: { type: String },
  default_destination: { type: Object },
  node: { type: Object },
  place_types: { type: Array, default: () => [] },
  direction: { type: String, default: 'row' }
})

const class_name = computed(() => {
  let class_name = 'q-col-12'
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    class_name = 'q-col-6'
  }
  if (props.direction === 'column') {
    class_name = 'q-col-12'
  }
  return class_name
})

const rules = {
  airport: [(v) => !!v || ''],
  destination: [(v) => !!v || '']
}

const default_payload = reactive({
  airport: null,
  destination: null,
  from_airport: true,
  round_trip: true,
  pickup_date: null,
  pickup_time: null,
  return_date: null,
  return_time: null,
  travelers: []
})

const package_store = stores.use_package()
const payload = computed(
  () => package_store.payload[props.node?.id]?.['transfer'] || default_payload
)

onMounted(() => {
  if (props.default_destination) {
    payload.value.destination = props.default_destination
  }
})

const swap_btn_styles = computed(() => {
  let result = {
    color: props.color
  }
  return result
})
</script>
