<template>
  <q-card v-bind="bind">
    <template v-if="node.meta.design === DEFAULT_TAB_DESIGN.id">
      <q-tabs
        v-model="node.meta.tab_id"
        v-bind="tab_bind"
        mobile-arrows
        no-caps
      >
        <q-tab
          v-for="tab in tabs"
          :style="gen_tab_styles(tab)"
          :key="tab.id"
          :name="tab.id"
        >
          <q-icon :name="tab.icon" size="sm" v-if="tab.icon" />
          <span class="text-weight-medium">
            {{ tab.label[context_store.language.code] }}
          </span>
        </q-tab>
      </q-tabs>
    </template>
    <template v-else-if="node.meta.design === STYLE1_TAB_DESIGN.id">
      <div class="q-row q-pa-sm" v-bind="button_group_container_bind">
        <div class="q-row q-pa-sm" v-bind="button_group_bind_style1">
          <q-btn
            v-bind="gen_button_bind_style1(tab)"
            @click="node.meta.tab_id = tab.id"
            v-for="tab in node.meta.tabs"
            :key="tab.id"
          />
        </div>
      </div>
    </template>
    <template v-else-if="node.meta.design === STYLE2_TAB_DESIGN.id">
      <q-tabs
        v-model="node.meta.tab_id"
        v-bind="tab_bind"
        class="egs-tabs-style2"
        mobile-arrows
        no-caps
      >
        <q-tab
          v-for="tab in tabs"
          :style="gen_tab_styles_2(tab)"
          :key="tab.id"
          :name="tab.id"
          class="egs-tab-style2"
        >
          <icon
            :name="tab.icon"
            :fill="
              node.meta.tab_id === tab.id
                ? node.meta.colors.active
                : node.meta.colors.text
            "
            :size_px="32"
            v-if="tab.icon"
          />
          <span class="text-weight-bold q-mt-sm">
            {{ tab.label[context_store.language.code] }}
          </span>
        </q-tab>
      </q-tabs>
    </template>
    <template v-else>
      <div class="q-row q-pa-sm" v-bind="button_group_container_bind">
        <div class="q-row q-pa-sm" v-bind="button_group_bind">
          <q-btn
            v-bind="gen_button_bind(tab)"
            @click="node.meta.tab_id = tab.id"
            v-for="tab in node.meta.tabs"
            :key="tab.id"
          />
        </div>
      </div>
    </template>
    <q-separator
      :color="node.meta.design === STYLE2_TAB_DESIGN.id ? 'white' : 'black'"
      v-if="node.meta.separator"
    />
    <div ref="content" v-bind="content_bind">
      <slot />
    </div>
  </q-card>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import {
  DEFAULT_TAB_DESIGN,
  NODE_BASE_PROPS,
  NODE_SIDES,
  STYLE1_TAB_DESIGN,
  STYLE2_TAB_DESIGN
} from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import Icon from 'src/components/common/icon'
import { stores } from 'src/stores'
import cloneDeep from 'lodash.clonedeep'
import { get_contrasting_color } from 'src/composables/utils'

const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()

const tabs = computed(() => props.node.meta.tabs)

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const gen_tab_styles = (tab) => {
  const meta = props.node.meta
  const active = meta.tab_id === tab.id

  let result = {}

  if (active) {
    result['color'] = meta.colors.active
  }

  return result
}

const gen_tab_styles_2 = (tab) => {
  const meta = props.node.meta
  const active = meta.tab_id === tab.id

  let result = {}

  if (active) {
    result['color'] = meta.colors.active
  }
  return { ...result, padding: '24px' }
}

const gen_button_bind = (tab) => {
  let result = {
    'no-caps': true,
    rounded: true,
    label: tab.label[context_store.language.code]
  }
  let styles = {}

  if (tab.id === props.node.meta.tab_id) {
    styles['background-color'] = props.node.meta.colors.active
    styles['color'] = '#ffffff'
    result['unelevated'] = true
  } else {
    styles['color'] = props.node.meta.colors.text
    result['flat'] = true
  }

  if (tab.icon) {
    result['icon'] = tab.icon
  }

  result['style'] = styles
  return result
}

const gen_button_bind_style1 = (tab) => {
  let result = {
    'no-caps': true,
    rounded: false,
    label: tab.label[context_store.language.code],
    size: props.node.meta.size ?? 'md',
    square: true
  }
  let styles = {}

  if (tab.id === props.node.meta.tab_id) {
    styles['background-color'] = props.node.meta.colors.active
    styles['color'] = get_contrasting_color(props.node.meta.colors.active ?? '#ffffff') ?? '#000000'
  } else {
    styles['color'] = props.node.meta.colors.text
    styles['background-color'] = 'white'
  }

  if (tab.icon) {
    result['icon'] = tab.icon
  }

  result['style'] = styles
  return result
}

const button_group_bind = computed(() => {
  const meta = props.node.meta

  let result = {}

  let styles = {
    'outline-color': meta.colors.text,
    'outline-style': 'solid',
    'outline-width': '1px'
  }
  let classes = ['q-btn--rounded']

  if (meta.align === 'justify') {
    styles['width'] = '100%'
    classes.push('q-btn-group--spread')
  }

  result['style'] = styles
  result['class'] = classes

  return result
})

const button_group_bind_style1 = computed(() => {
  const meta = props.node.meta

  let result = {}

  let styles = {
    gap: '24px'
  }
  let classes = []

  if (meta.align === 'justify') {
    styles['width'] = '100%'
    // classes.push('q-btn-group--spread')
  }

  result['style'] = styles
  result['class'] = classes

  return result
})
const button_group_container_bind = computed(() => {
  const meta = props.node.meta

  let result = {}

  let styles = {
    // 'margin-bottom': '24px'
  }
  let classes = []

  if (meta.align === 'right') {
    classes.push('justify-end')
  } else if (meta.align === 'center') {
    classes.push('justify-center')
  }

  result['style'] = styles
  result['class'] = classes

  return result
})

const tab_bind = computed(() => {
  let result = {}
  const meta = props.node.meta
  result['align'] = meta.align
  return result
})

const bind = computed(() => {
  let style = cloneDeep(styles.value)

  Object.keys(NODE_SIDES).forEach((side) => {
    delete style[`padding-${side}`]
  })

  let result = {}

  const meta = props.node.meta

  if (meta.flat) {
    result['flat'] = true
  }
  if (meta.bordered) {
    result['bordered'] = true
  }

  if (meta.transparent_background) {
    style['background-color'] = 'transparent'
  } else {
    style['background-color'] = meta.colors.background
  }

  style['color'] = meta.colors.text

  result['style'] = style

  return result
})

const content_bind = computed(() => {
  let style = cloneDeep(styles.value)
  Object.keys(NODE_SIDES).forEach((side) => {
    delete style[`margin-${side}`]
  })
  const meta = props.node.meta

  if (meta.design === STYLE2_TAB_DESIGN.id) {
    style['background-color'] = '#f3f0eff2'
  }

  let result = {
    style: style
  }

  return result
})
</script>
