<template>
  <div class="q-row q-col-gutter-sm" style="position: relative">
    <div :class="class_name">
      <place-select
        :key="modelValue.dp_airport?.id"
        v-model="modelValue.dp_airport"
        :arrival_code="
          product.static_route_enabled && modelValue.ar_airport
            ? modelValue.ar_airport.code
            : ''
        "
        :cities_with_airports="cities_with_airports"
        :destination_type="product.static_route_enabled ? 'departure' : ''"
        :has_code="has_code"
        :label="$t('common.origin')"
        :rules="rules.dp_airport"
        :static_route_enabled="product.static_route_enabled"
        :types="place_types"
        :validate="(item) =>validate('origin', item)"
        dense
        fill-input
        hide-bottom-space
        hide-selected
        outlined
        prepend_icon="place"
      />
    </div>
    <div :class="class_name">
      <place-select
        :key="modelValue.ar_airport?.id"
        v-model="modelValue.ar_airport"
        :cities_with_airports="cities_with_airports"
        :departure_code="
          product.static_route_enabled && modelValue.dp_airport
            ? modelValue.dp_airport.code
            : ''
        "
        :destination_type="product.static_route_enabled ? 'arrival' : ''"
        :has_code="has_code"
        :label="$t('common.destination')"
        :rules="rules.ar_airport"
        :static_route_enabled="product.static_route_enabled"
        :types="place_types"
        :validate="(item) =>validate('destination', item)"
        dense
        fill-input
        hide-bottom-space
        hide-selected
        outlined
        prepend_icon="place"
      />
    </div>
    <q-btn
      :icon="icon"
      :style="`color: ${color}`"
      class="bg-white swap-flight-route-btn"
      dense
      outline
      round
      @click="$emit('handle_swap_airport')"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import PlaceSelect from 'src/components/common/place-select/index.vue'
import { stores } from 'src/stores'
import { DESKTOP_VIEWPORT } from 'src/constants'

const props = defineProps({
  product: { type: Object, required: true },
  color: { type: String },
  modelValue: { type: Object, required: true },
  has_code: { type: Boolean, default: false },
  place_types: { type: Array, default: () => ['airport'] },
  cities_with_airports: { type: Boolean, default: true },
  direction: { type: String, default: 'row' },
  validate: { type: Function, default: () => true },
  rules: { type: Object }
})

const context_store = stores.use_context()

const class_name = computed(() => {
  let class_name = 'q-col-12'
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    class_name = 'q-col-6'
  }
  if (props.direction === 'column') {
    class_name = 'q-col-12'
  }
  return class_name
})

const icon = computed(() => {
  return props.direction === 'column'
    ? 'swap_vert'
    : context_store.viewport === DESKTOP_VIEWPORT
    ? 'swap_horiz'
    : 'swap_vert'
})

</script>
