<template>
  <q-select
    v-bind="v_bind"
    :label="show_label ? 'Hotel Stars' : undefined"
    @update:model-value="handle_changes"
  >
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <!--eslint-disable vue/no-v-text-v-html-on-component-->
          <q-item-label class="text-capitalize" v-html="$t(scope.opt.label)" />
        </q-item-section>
      </q-item>
    </template>
    <template v-if="display_value" v-slot:selected>
      <q-item-label :lines="1" class="text-capitalize">
        {{ $t(display_value) }}
      </q-item-label>
    </template>
    <template v-if="show_icon" v-slot:prepend>
      <q-icon class="cursor-pointer" name="star" />
    </template>
  </q-select>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: { type: Number },
  hide_label_mode: { type: Boolean },
  label: { type: String },
  class: { type: String },
  show_icon: { type: Boolean, default: false },
  show_label: { type: Boolean, default: true }
})
const star_options = [
  { value: null, label: `common.any` },
  { value: 1, label: `common.1-star` },
  { value: 2, label: `common.2-stars` },
  { value: 3, label: `common.3-stars` },
  { value: 4, label: `common.4-stars` },
  { value: 5, label: `common.5-stars` }
]

const display_value = computed(() => {
  const selected = star_options.find((i) => i.value === props.modelValue)
  return selected?.label || ''
})

const v_bind = computed(() => {
  const result = {
    'model-value': props.modelValue,
    options: star_options,
    'option-label': 'label',
    'option-value': 'value'
  }

  if (props.hide_label_mode) {
    result.class = props.class
  }

  // const hide_label_condition = props.hide_label_mode ? !!props.modelValue : false
  const hide_label_condition = true
  if (props.label && !hide_label_condition) {
    result.label = props.label
  }

  return result
})

const handle_changes = (val) => {
  emit('update:modelValue', val)
}
</script>

<style lang="scss">
.expedia-stars-select {
  width: 130px;
  text-transform: initial;

  .q-field__control-container {
    padding-top: 0 !important;
  }

  .q-field__label {
    top: 6px !important;
    transform: none !important;
  }

  .q-field__control,
  .q-field__native {
    min-height: 32px !important;
  }

  .q-field__marginal {
    height: 32px !important;
  }
}
</style>
