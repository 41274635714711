<template>
  <div v-if="payload" :style="styles" :key="render_key">
    <hotel-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      @submit="handle_submit"
      :styles="styles"
      :color="color"
      :design="design"
      :hotel="packages['hotel']"
      :product="products['hotel']"
      :place_types="place_types['hotel']"
      :setting="settings['hotel']"
      :node="node"
      v-if="product_combine === 'hotel'"
    />
    <flight-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :flight="packages['flight']"
      :product="products['flight']"
      :color="color"
      :design="design"
      :node="node"
      :place_types="place_types['flight']"
      :setting="settings['flight']"
      v-else-if="product_combine === 'flight'"
    />
    <bundle-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :bundle="packages['flight--hotel']"
      :place_types="place_types['flight--hotel']"
      :product="products['flight']"
      :node="node"
      :setting="settings['bundle']"
      v-else-if="product_combine === 'flight--hotel'"
    />
    <tour-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :tour="packages['tour']"
      :product="products['tour']"
      :place_types="place_types['tour']"
      :node="node"
      :setting="settings['tour']"
      v-else-if="product_combine === 'tour'"
    />
    <transfer-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :transfer="packages['transfer']"
      :product="products['transfer']"
      :place_types="place_types['transfer']"
      :node="node"
      :setting="settings['transfer']"
      v-else-if="product_combine === 'transfer'"
    />
    <car-rental-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :car_rental="packages['car_rental']"
      :product="products['car_rental']"
      :place_types="place_types['car_rental']"
      :node="node"
      :setting="settings['car_rental']"
      v-else-if="product_combine === 'car_rental'"
    />
    <tour-package-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :tour_package="packages['tour_package']"
      :product="products['tour_package']"
      :place_types="place_types['tour_package']"
      :node="node"
      :setting="settings['tour_package']"
      v-else-if="product_combine === 'tour_package'"
    />
    <train-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :flight="packages['train']"
      :product="products['train']"
      :color="color"
      :design="design"
      :node="node"
      :setting="settings['train']"
      v-else-if="product_combine === 'train'"
    />
    <train-hotel-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :package="packages['hotel--train']"
      :place_types="place_types['hotel--train']"
      :product="products['train']"
      :node="node"
      :setting="settings['hotel_train']"
      v-else-if="product_combine === 'hotel--train'"
    />
    <div v-else>
      <q-item-label class="text-center" caption>
        Not supported for this combination
      </q-item-label>
    </div>
  </div>
</template>
<script setup>
import { defineProps, onMounted, computed, ref } from 'vue'
import is_empty from 'lodash.isempty'
import { stores } from 'src/stores'
import HotelForm from './hotel'
import FlightForm from './flight'
import BundleForm from './bundle'
import TourForm from './tour'
import TransferForm from './transfer'
import CarRentalForm from './car-rental'
import TourPackageForm from './tour-package'
import TrainForm from './train'
import TrainHotelForm from './train-hotel'
import {
  check_cache_value,
  get_package_query,
  package_default_data
} from 'src/composables/utils/package-form'
import { trigger_custom_event } from 'src/composables/utils'
import { PACKAGE_FROM_SUBMIT_EVENT } from 'src/composables/utils/forms'

const props = defineProps({
  styles: { type: Object },
  color: { type: String },
  packages: { type: Object },
  products: { type: Object },
  product_combine: { type: String },
  handle_submit: { type: Function },
  default_destination: { type: Object },
  flight_campaign: { type: String },
  partner_id: { type: String },
  design: { type: String },
  node: { type: Object }
})

const package_store = stores.use_package()
const context_store = stores.use_context()

const payload = computed(() => package_store.payload?.[props.node?.id])
const settings = computed(() => context_store.settings)

const render_key = ref(0)

const handle_submit = (data) => {
  if (context_store.enable_submit_event) {
    trigger_custom_event(PACKAGE_FROM_SUBMIT_EVENT, data)
  } else {
    props.handle_submit(data)
  }
}

const place_types = computed(() => {
  return {
    hotel: ['country', 'administrative_area_level_4'],
    flight: ['airport'],
    'flight--hotel': ['airport'],
    tour: ['administrative_area_level_4'],
    transfer: ['airport'],
    car_rental: ['airport'],
    tour_package: ['airport', 'administrative_area_level_4'],
    train: ['railway_station'],
    'hotel--train': ['railway_station']
  }
})

onMounted(async () => {
  if (is_empty(payload.value)) {
    package_store.payload[props.node.id] = package_default_data
  } else {
    check_cache_value(package_store.payload[props.node.id])
  }
  await get_package_query(package_store, props.node.id)
  render_key.value += 1
})
</script>
